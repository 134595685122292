.player-wrapper {
  position: relative;
  padding-top: 56.25%; }

.react-player {
  position: absolute;
  top: 0;
  left: 0; }

.react-player__shadow {
  left: 2rem;
  bottom: 2rem;
  position: absolute;
  background: url(https://assets.primaverasound.com/2019/play-icon.png) no-repeat center center !important;
  background-size: 100%!important; }

.react-player__play-icon {
  display: none!important; }

.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  height: 100vh;
  height: calc(100vh - 105px);
  iframe {
   border: 0;
   height: 100vh;
   height: calc(100vh - 105px);
   left: 0;
   position: absolute;
   top: 0;
   width: 100%; } }

@font-face {
  font-family: 'GT-America-Mono-Bold-Italic';
  src: url('https://assets.primaverasound.com/psweb/fonts/GT-America-Mono-Bold-Italic.woff2') format("woff2"), url('https://assets.primaverasound.com/psweb/fonts/GT-America-Mono-Bold-Italic.woff') format("woff"); }

@font-face {
  font-family: 'GT-America-Mono-Bold';
  src: url('https://assets.primaverasound.com/psweb/fonts/GT-America-Mono-Bold.woff2') format("woff2"), url('https://assets.primaverasound.com/psweb/fonts/GT-America-Mono-Bold.woff') format("woff"); }


@font-face {
  font-family: 'GT-America-Mono-Medium-Italic';
  src: url('https://assets.primaverasound.com/psweb/fonts/GT-America-Mono-Medium-Italic.woff2') format("woff2"), url('https://assets.primaverasound.com/psweb/fonts/GT-America-Mono-Medium-Italic.woff') format("woff"); }


@font-face {
  font-family: 'GT-America-Mono-Medium';
  src: url('https://assets.primaverasound.com/psweb/fonts/GT-America-Mono-Medium.woff2') format("woff2"), url('https://assets.primaverasound.com/psweb/fonts/GT-America-Mono-Medium.woff') format("woff"); }


@font-face {
  font-family: 'GT-America-Mono-Mono-Regular-Italic';
  src: url('https://assets.primaverasound.com/psweb/fonts/GT-America-Mono-Regular-Italic.woff2') format("woff2"), url('https://assets.primaverasound.com/psweb/fonts/GT-America-Mono-Regular-Italic.woff') format("woff"); }


@font-face {
  font-family: 'GT-America-Mono-Regular';
  src: url('https://assets.primaverasound.com/psweb/fonts/GT-America-Mono-Regular.woff2') format("woff2"), url('https://assets.primaverasound.com/psweb/fonts/GT-America-Mono-Regular.woff') format("woff"); }


@font-face {
  font-family: 'GT-America-Standard-Black-Italic';
  src: url('https://assets.primaverasound.com/psweb/fonts/GT-America-Standard-Black-Italic.woff2') format("woff2"), url('https://assets.primaverasound.com/psweb/fonts/GT-America-Standard-Black-Italic.woff') format("woff"); }


@font-face {
  font-family: 'GT-America-Standard-Black';
  src: url('https://assets.primaverasound.com/psweb/fonts/GT-America-Standard-Black.woff2') format("woff2"), url('https://assets.primaverasound.com/psweb/fonts/GT-America-Standard-Black.woff') format("woff"); }
@font-face {
  font-family: 'GT-America-Standard-Bold';
  src: url('https://assets.primaverasound.com/psweb/fonts/GT-America-Standard-Bold.woff2') format("woff2"), url('https://assets.primaverasound.com/psweb/fonts/GT-America-Standard-Bold.woff') format("woff"); }

@font-face {
  font-family: 'GT-America-Standard-Medium';
  src: url("https://s3-eu-west-1.amazonaws.com/public.primaverasound.com/fonts/GT-America-Standard-Medium.woff2") format("woff2"), url("https://s3-eu-west-1.amazonaws.com/public.primaverasound.com/fonts/GT-America-Standard-Medium.woff") format("woff"); }

@font-face {
  font-family: 'GT-America-Standard-Regular';
  src: url('https://assets.primaverasound.com/psweb/fonts/GT-america-standard-regular.woff2') format("woff2"), url('https://assets.primaverasound.com/psweb/fonts/GT-america-standard-regular.woff') format("woff"); }

@font-face {
  font-family: 'TiemposTextWeb-Bold';
  src: url('https://assets.primaverasound.com/psweb/fonts/TiemposTextWeb-Bold.woff2') format("woff2"), url('https://assets.primaverasound.com/psweb/fonts/TiemposTextWeb-Bold.woff') format("woff"); }


@font-face {
  font-family: 'TiemposTextWeb-BoldItalic';
  src: url('https://assets.primaverasound.com/psweb/fonts/TiemposTextWeb-BoldItalic.woff2') format("woff2"), url('https://assets.primaverasound.com/psweb/fonts/TiemposTextWeb-BoldItalic.woff') format("woff"); }


@font-face {
  font-family: 'TiemposTextWeb-Medium';
  src: url('https://assets.primaverasound.com/psweb/fonts/TiemposTextWeb-Medium.woff2') format("woff2"), url('https://assets.primaverasound.com/psweb/fonts/TiemposTextWeb-Medium.woff') format("woff"); }


@font-face {
  font-family: 'TiemposTextWeb-MediumItalic';
  src: url('https://assets.primaverasound.com/psweb/fonts/TiemposTextWeb-MediumItalic.woff2') format("woff2"), url('https://assets.primaverasound.com/psweb/fonts/TiemposTextWeb-MediumItalic.woff') format("woff"); }


@font-face {
  font-family: 'TiemposTextWeb-Regular';
  src: url('https://assets.primaverasound.com/psweb/fonts/TiemposTextWeb-Regular.woff2') format("woff2"), url('https://assets.primaverasound.com/psweb/fonts/TiemposTextWeb-Regular.woff') format("woff"); }


@font-face {
  font-family: 'TiemposTextWeb-RegularItalic';
  src: url('https://assets.primaverasound.com/psweb/fonts/TiemposTextWeb-RegularItalic.woff2') format("woff2"), url('https://assets.primaverasound.com/psweb/fonts/TiemposTextWeb-RegularItalic.woff') format("woff"); }


@font-face {
  font-family: 'TiemposTextWeb-Semibold';
  src: url('https://assets.primaverasound.com/psweb/fonts/TiemposTextWeb-Semibold.woff2') format("woff2"), url('https://assets.primaverasound.com/psweb/fonts/TiemposTextWeb-Semibold.woff') format("woff"); }


@font-face {
  font-family: 'TiemposTextWeb-SemiboldItalic';
  src: url('https://assets.primaverasound.com/psweb/fonts/TiemposTextWeb-SemiboldItalic.woff2') format("woff2"), url('https://assets.primaverasound.com/psweb/fonts/TiemposTextWeb-SemiboldItalic.woff') format("woff"); }

@font-face {
  font-family: 'Icons';
  src:  url('https://assets.primaverasound.com/psweb/fonts/icons.woff') format("woff"); }

@font-face {
  font-family: 'newIcons';
  src:  url('https://assets.primaverasound.com/psweb/fonts/newIcons.woff') format("woff"); }

.scrollToTop-Button-Wrapper {
  $size: 4rem;
  $offsetBottom: 23rem;
  $offsetHorizontal: 1rem;
  $scrollToRevealDistance: 12rem;
  bottom: 80px;
  right: $offsetHorizontal;
  pointer-events: none;
  z-index: 1000;

  @supports (-moz-appearance: meterbar) {
    clip: rect(0, $size, auto, 0);
  }
  .scrollToTop-Button-new {
    width: $size;
    height: $size;
    opacity: 0;
    pointer-events: all;
    cursor: pointer;
    &.scrollbutton__in{
      opacity: 1;
    }
    svg {
      width: 100%;
      height: 100%;

      path {
        transition: all 0.1s;
      }
    }
  }
  .scrollToTop-Button {
    $offset: - ($size + $offsetBottom);
    width: $size;
    height: $size;
    top: $offset;
    margin-bottom: $offset;
    transform: translateY(100vh);
    pointer-events: all;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;

      path {
        transition: all 0.1s;
      }
    }

    .scrollToTop-Button-Arrow {
      fill: black;
      transform: scale(0.66);
      transform-origin: center;
    }
  }

  .scrollToTop-Button:hover {
    .scrollToTop-Button-Arrow {
      fill: lighten(black, 50%);
    }
  }
}
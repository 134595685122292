@import "mixins";
@import "variables";
@import "fonts";
@import "buttons";
@import "base";
@import "TopHeader";
@import "Launcher";
@import "artist";
@import "scroll";
@import "material";
@import "~slick-carousel/slick/slick.css";
@import "slick.scss";
@import "./react-datepicker";
@import "react-player";
@import "emojimart";
@import "chat";
@import "icons";
@import "~react-id-swiper/lib/styles/scss/swiper.scss";
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css");

// the cloud
.simple-cloud .tag-cloud-tag {
  cursor: pointer;
}

.simple-cloud .tag-cloud-tag:hover {
  text-decoration: underline;
}

//swiper carousel changes

.swiper-wrapper {
  margin-left: auto;
  margin-right: auto;
}

.swiper-pagination-mobile {
  padding-left: 1.65rem;
  text-align: left;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  left: 0;
  width: 100%;
  bottom: 21px;
  position: absolute;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.swiper-container-horizontal > .swiper-pagination-tickets {
  text-align: center;
  bottom: 0;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
  left: 19px;
  right: auto;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
  right: 19px;
  left: auto;
}

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, .25);
  position: absolute;

  .swiper-pagination-progressbar-fill {
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: scale(0);
    transform-origin: left top;
  }
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: transparent;
  border: 1px white solid;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #fff;
}

.swiper-pagination-tickets .swiper-pagination-bullet {
  background: transparent;
  border: 1px black solid;
}

.swiper-pagination-tickets .swiper-pagination-bullet-active {
  opacity: 1;
  background: #000;
}

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff;
}

.swiper-pagination-progressbar.swiper-pagination-white {
  background: rgba(255, 255, 255, .25);

  .swiper-pagination-progressbar-fill {
    background: #fff;
  }
}

.swiper-slide.w-auto {
  padding-bottom: 20px;
  margin-right: 1rem;
}

.swiper-slide.w-auto:last-child {
  margin-right: 0;
}

.collapsible-page .swiper-slide.w-auto {
  margin-right: 1rem;
  margin-left: 0;
  padding-bottom: 20px;
}

.c-tickets {
  margin-left: auto;
  margin-right: auto;
}

.collapsible-page .c-tickets {
  margin-left: 0;
}

.modal-gallery-slider .swiper-container {
  width: 100%;
}

.thumbnail-slider .swiper-slide {
  border: 2px solid transparent;
}

.thumbnail-slider .swiper-slide-active {
  border: 2px solid white;
}

//pin halftop and halfbottom
.pin-ht {
  top: 50%;
}

.pin-hb {
  bottom: 50%;
}

.rotate-180 {
  transform: rotate(180deg);
}

// ------------------------------------------------------------------------------------------------------------------------------

[class^="MuiFormControl-root"] {
  max-width: 100%;
  width: 100%;

  [class^="MuiSelect-root"] {
    display: flex;
    border: 1px solid #fff;
    border-radius: 999px;
    font-family: GT-America-Mono-Regular;
    color: #fff;
    padding-left: .75rem;

    &.active {
      color: #000;
      background: #fff;
    }

    [class^="MuiSelect-select"] {
      flex: 1;
      padding: 6px 0px 6px 16px;
      overflow: hidden;
      width: 50px;
      z-index: 50;

      > div {
        display: flex;
        align-items: center;
      }
    }
  }

  [class^="MuiInputBase-root"] {
    &:before,
    &:after {
      display: none !important;
    }
  }

  [class^="MuiSelect-select"] {
    padding-left: 8px;

    &:focus {
      background: none !important;
    }
  }

  img {
    -webkit-animation: none;
            animation: none;
    filter: none;
  }
}

.country-list {
  padding: 0;
  padding-top: 7px;
  height: 20vh !important;
  background: #000;
  border-radius: 8px;
  overflow-y: scroll;
  margin-top: 6rem;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 13px solid #000;
    top: 0;
    left: 16px;
  }
}

[class^="MuiPaper-root"] {
  box-shadow: none !important;
  background: none !important;
  margin-top: 5px;
  border-radius: 0 !important;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    top: 0;
    left: 16px;
  }

  [class^="MuiList-root"] {
    padding: 0;
    padding-top: 7px;
    height: 20vh !important;
    margin-top: 13px;
    background: #fff;
    border-radius: 8px;
    overflow-y: auto;
    position: relative;
  }

  [class^="MuiButtonBase-root"] {
    color: #000 !important;
    font-family: GT-America-Mono-Regular !important;
    font-size: 12px;
    border-bottom: 0;
    padding: 8px 30px 8px 15px;
    background: none !important;

    > div {
      display: flex;
      align-items: center;
    }

    img {
      -webkit-animation: none;
              animation: none;
      filter: none;
    }
  }
}

// ------------------------------------------------------------------------------------------------------------------------------

.carousel75-is-animating {
  transition: transform 400ms cubic-bezier(.5, 0, .5, 1);

  [class^="MuiInputBase-root"] {
    &:before,
    &:after {
      display: none !important;
    }
  }
}

// ------------------------------------------------------------------------------------------------------------------------------

.scrollbar__black::-webkit-scrollbar-track {
  border: 1px solid black;
  background-color: #f5f5f5;
}

.scrollbar__black::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.scrollbar__black::-webkit-scrollbar-thumb {
  background-color: #000;
}

.scrollbar__hidden::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}

.collapsible-sidebar {
  top: auto;
  bottom: auto;
}

.sticky-sidebar {
  position: fixed !important;
  padding-top: 7.5rem !important;
  padding-right: 2rem !important;
  margin-right: 16px !important;
}

.sticky-sidebar.sticky-top {
  top: 0 !important;
  bottom: auto !important;
}

.sticky-sidebar.sticky-bottom {
  bottom: 352px !important;
  top: auto !important;
}

.MuiCollapse-wrapperInner .c-tickets {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.tag-16 {
  font-size: 16px;
  font-family: "GT-America-Mono-Regular";
}

.tag-22 {
  font-size: 22px;
  font-family: "GT-America-Mono-Medium";
}

.tag-28 {
  font-size: 28px;
  font-family: "GT-America-Mono-Medium";
}

.tag-34 {
  font-size: 34px;
  font-family: "GT-America-Mono-Bold";
}

.tag-40 {
  font-size: 40px;
  font-family: "GT-America-Standard-Black";
}

.artistcloud-tag:last-child span:last-child {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border-radius: 9999px;
}

div[role="dialog"] input,
select,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border-radius: 0px;
}

div[role="dialog"] input:focus,
select:focus,
button:focus {
  outline: none !important;
}

div[role="dialog"] input:focus-visible,
select:focus-visible,
button:focus-visible {
  outline: #7dd6ad auto 1px !important;
}

div[role="dialog"] button {
  color: #000 !important;
}

div[role="dialog"] button > span {
  color: #949494 !important;
}

div[role="dialog"] .bg-spotify > span {
  color: #fff !important;
}

div[role="dialog"] input::-moz-placeholder {
  color: #949494 !important;
}

div[role="dialog"] input:-ms-input-placeholder {
  color: #949494 !important;
}

div[role="dialog"] input::placeholder {
  color: #949494 !important;
}

button,
[type="button"],
[type="reset"],
[type="submit"] :not(div[role="dialog"] button) {
  border-radius: 0;
}

div[role="dialog"] button {
  border-radius: 9999px;
}

#reward-drawer button {
  border-radius: .25rem;
}

button#musicMeetsButton,
[type="button"]#musicMeetsButton,
[type="reset"]#musicMeetsButton,
[type="submit"]#musicMeetsButton {
  border-radius: 9999px;
}

.formik-textarea {
  resize: none;
}

.formik-social-pro::-moz-placeholder {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "GT-America-Mono-Bold";
  line-height: 14px;
  color: #000;
  opacity: 1;
}

.formik-social-pro:-ms-input-placeholder {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "GT-America-Mono-Bold";
  line-height: 14px;
  color: #000;
  opacity: 1;
}

.formik-social-pro::placeholder {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "GT-America-Mono-Bold";
  line-height: 14px;
  color: #000;
  opacity: 1;
}

.formik-social-pro:focus::-webkit-input-placeholder {
  color: transparent;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: block;
}

.header-MusicMeets-animation {
  transition: transform .3s ease-in-out;
  transform: translateY(-100%);
}

.header-MusicMeets-animation-visible {
  transition: transform .3s ease-in-out;
  transform: translateY(0%);
}

.sticky-directory-letters {
  position: sticky;
  top: 0;
  left: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.sticky-directory-letters::-webkit-scrollbar {
  display: none;
}

.min-w-1\/3 {
  min-width: 33.33333%;
}

.min-w-1\/4 {
  min-width: 25%;
}

.min-w-1\/6 {
  min-width: 16.66667%;
}

.min-w-1\/12 {
  min-width: 8.333%;
}

.ab-feed.ab-effect-slide.ab-show {
  box-shadow: -25px 0 20px -20px rgba(0, 0, 0, .25);
}

.ab-feed-body {
  background: #333;
  border-style: none !important;
}

.ab-feed .ab-card {
  color: white !important;
  border-style: none !important;
  background-color: #4e4e4e !important;
  box-shadow: none !important;
  border-radius: .5rem !important;
  padding: 1rem;
}

.ab-feed .ab-card div > a {
  color: black !important;
  font-weight: 500 !important;
}

.ab-title {
  font-family: "GT-America-Standard-Bold" !important;
  padding: 0 !important;
  margin-bottom: .8rem !important;
}

.ab-description {
  font-family: "GT-America-Standard-Regular" !important;
  color: white !important;
  display: flex;
  flex-direction: column;
  padding: 0 !important;
}

.ab-image-area {
  border-radius: .4rem;
  -o-object-fit: cover;
     object-fit: cover;
  margin-bottom: .8rem !important;
}

.ab-url-area {
  text-transform: uppercase;
  text-align: center;
  font-size: .8rem;
  font-weight: 300;
  border-width: 0px;
  border-radius: 9999px;
  background-color: #7dd6ad;
  cursor: pointer;
  padding: .6rem 2.2rem;
  margin: 0 auto;
  margin-top: 1.4rem;
  margin-bottom: .5rem;
  color: #000;
}

body .ab-feed .ab-feed-buttons-wrapper {
  background-color: #000;
  box-shadow: none;
}

.aspect-square {
  aspect-ratio: 1/1;

  @supports not (aspect-ratio: 1 / 1) {
    &::before {
      float: left;
      padding-top: 100%;
      content: "";
    }

    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }
}

.object-cover {
  width: 100%;
  min-height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

#-ui-panel-div-children- {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#-ui-panel-div-children-::-webkit-scrollbar {
  display: none;
}

// Loader.jsx animation (ps/MusicMeets)
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// RewardsDrawer.jsx animation (ps/UserPanel)
@keyframes openDrawer {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

.open-drawer {
  -webkit-animation-name: openDrawer;
          animation-name: openDrawer;
  -webkit-animation-duration: 300ms;
          animation-duration: 300ms;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}

@-webkit-keyframes closeDrawer {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(100%);
    display: none;
  }
}

@keyframes closeDrawer {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(100%);
    display: none;
  }
}

.close-drawer {
  -webkit-animation-name: closeDrawer;
          animation-name: closeDrawer;
  -webkit-animation-duration: 300ms;
          animation-duration: 300ms;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
}

.drawer-down {
  transition-property: padding-top;
  transition-duration: 100ms;
  transition-timing-function: 1;
  transition-delay: 0;
  padding-top: 6rem;
}

.drawer-up {
  transition-property: padding-top;
  transition-duration: 100ms;
  transition-timing-function: 1;
  transition-delay: 0;
  padding-top: 2rem;
}

@-webkit-keyframes fadeIn50 {
  from {
    opacity: 0%;
  }

  to {
    opacity: 50%;
  }
}

@keyframes fadeIn50 {
  from {
    opacity: 0%;
  }

  to {
    opacity: 50%;
  }
}

@-webkit-keyframes fadeOut50 {
  from {
    opacity: 50%;
  }

  to {
    opacity: 0%;
    display: none;
  }
}

@keyframes fadeOut50 {
  from {
    opacity: 50%;
  }

  to {
    opacity: 0%;
    display: none;
  }
}

.drawer-bg-in {
  -webkit-animation-name: fadeIn50;
          animation-name: fadeIn50;
  -webkit-animation-duration: 300ms;
          animation-duration: 300ms;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}

.drawer-bg-out {
  -webkit-animation-name: fadeOut50;
          animation-name: fadeOut50;
  -webkit-animation-duration: 300ms;
          animation-duration: 300ms;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}

.notification-cards {
  overflow: scroll;
}

.notification-cards::-webkit-scrollbar {
  display: none;
}

.notification-cards > div {
  margin: 1rem;
}

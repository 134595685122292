.font-icon {
  font-family: Icons; }
.font-icon1 {
  font-family: newIcons; }

.icon-arrow-left:before {
  content: "\e900"; }

.icon-mixcloud:before {
  content: "\e901"; }

.icon-primavera-pro:before {
  content: "\e902"; }
.icon-radio-ps:before {
  content: "\e903"; }
.icon-primavera-sound:before {
  content: "\e904"; }
.icon-labels:before {
  content: "\e905"; }
.icon-ps-logo-small:before {
  content: "\e906"; }
.icon-ride:before {
  content: "\e907"; }
.icon-share:before {
  content: "\e908"; }
.icon-sun:before {
  content: "\e909"; }
.icon-share2:before {
  content: "\e90a"; }
.icon-google-plus:before {
  content: "\e90b"; }
.icon-arrow-left-solid:before {
  content: "\e90c"; }
.icon-arrow-left-round:before {
  content: "\e90d"; }
.icon-youtube:before {
  content: "\e90e"; }
.icon-twitter:before {
  content: "\e90f"; }
.icon-twitter-solid:before {
  content: "\e910"; }
.icon-tram:before {
  content: "\e911"; }
.icon-plus:before {
  content: "\e912"; }
.icon-player:before {
  content: "\e913"; }
.icon-player-solid:before {
  content: "\e914"; }
.icon-pinterest:before {
  content: "\e915"; }
.icon-link:before {
  content: "\e916"; }
.icon-less:before {
  content: "\e917"; }
.icon-instagram:before {
  content: "\e918"; }
.icon-facebook:before {
  content: "\e919"; }
.icon-facebook-solid:before {
  content: "\e91a"; }
.icon-email:before {
  content: "\e91b"; }
.icon-download1:before {
  content: "\e91c"; }
.icon-checkbox:before {
  content: "\e91d"; }
.icon-burger:before {
  content: "\e91e"; }
.icon-arrow-up:before {
  content: "\e91f"; }
.icon-arrow-right:before {
  content: "\e920"; }
.icon-arrow-right-round:before {
  content: "\e921"; }
.icon-arrow-right-solid:before {
  content: "\e922"; }
.icon-arrow-down:before {
  content: "\e923"; }
.icon-apple:before {
  content: "\e924"; }
.icon-recycle-round:before {
  content: "\e925"; }
.icon-bus:before {
  content: "\e926"; }
.icon-zoom:before {
  content: "\e927"; }
.icon-grid:before {
  content: "\e928"; }
.icon-star-solid:before {
  content: "\e929"; }
.icon-ipad:before {
  content: "\e92a"; }
.icon-cigar:before {
  content: "\e92b"; }
.icon-beach:before {
  content: "\e92c"; }
.icon-toilets:before {
  content: "\e92d"; }
.icon-flora:before {
  content: "\e92e"; }
.icon-package:before {
  content: "\e92f"; }
.icon-water:before {
  content: "\e930"; }
.icon-cup:before {
  content: "\e931"; }
.icon-greener-festival:before {
  content: "\e932"; }
.icon-greenpeace:before {
  content: "\e933"; }
.icon-staff:before {
  content: "\e934"; }
.icon-sustainable-mat:before {
  content: "\e935"; }
.icon-c02:before {
  content: "\e936"; }
.icon-taxi:before {
  content: "\e937"; }
.icon-subway:before {
  content: "\e938"; }
.icon-bicycle:before {
  content: "\e939"; }
.icon-car:before {
  content: "\e93a"; }
.icon-train:before {
  content: "\e93b"; }
.icon-boat:before {
  content: "\e93c"; }
.icon-plane:before {
  content: "\e93d"; }
.icon-star:before {
  content: "\e93e"; }
.icon-burger-menu:before {
  content: "\e93f"; }
.icon-primavera-tree:before {
  content: "\e940"; }
.icon-login:before {
  content: "\e941"; }
.icon-search:before {
  content: "\e942"; }
.icon-ticket:before {
  content: "\e943"; }

.icon-lineup-grid-switch:before {
  content: "\e944"; }

.icon-pause:before {
  content: "\e945"; }

.icon-close:before {
  content: "\e946"; }

.icon-mixcloud-mini:before {
  content: "\e947"; }

.icon-clock:before {
  content: "\e948"; }

.icon-current2 .path1:before {
  content: "\e949"; }

.icon-current2 .path2:before {
  content: "\e94a";
  margin-left: -1em;
  opacity: 0.1766; }
.icon-current2 .path3:before {
  content: "\e94b";
  margin-left: -1em;
  opacity: 0.3759; }
.icon-current2 .path4:before {
  content: "\e94c";
  margin-left: -1em;
  opacity: 0.1506; }

.icon-arrow-down-round:before {
  content: "\e94d"; }

.icon-current .path1:before {
  content: "\e94e";
  color: rgb(255, 123, 160); }

.icon-current .path2:before {
  content: "\e94f";
  margin-left: -1.0322265625em;
  color: rgb(255, 123, 160);
  opacity: 0.3759; }
.icon-current .path3:before {
  content: "\e950";
  margin-left: -1.0322265625em;
  color: rgb(255, 123, 160);
  opacity: 0.3206; }

.icon-facebook-simple:before {
  content: "\e951"; }

.icon-current .path4:before {
  content: "\e952";
  margin-left: -1.0322265625em;
  color: rgb(255, 255, 255); }

.icon-arrow-up-round:before {
  content: "\e953"; }

.icon-twitter-simple:before {
  content: "\e954"; }

.icon-current2 .path5:before {
  content: "\e955";
  margin-left: -1em;
  opacity: 0.3206; }
.icon-current2 .path6:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(3, 80, 225); }

.icon-arrow-left-fine:before {
  content: "\e957"; }

.icon-arrow-right-fine:before {
  content: "\e958"; }

.icon-seat:before {
  content: "\e959"; }

.icon-quotes:before {
  content: "\e95a"; }

.icon-instagram-logo:before {
  content: "\e95b"; }

.icon-add-image3:before {
  content: "\e95c"; }

.icon-add-image3:before {
  content: "\e95d"; }

.icon-file:before {
  content: "\e960"; }

.icon-video-file:before {
  content: "\e961"; }

.icon-music-file:before {
  content: "\e962"; }

.icon-ticketmaster-logo:before {
  content: "\e963"; }

.icon-redtkt-logo:before {
  content: "\e964"; }

.icon-add-button:before {
  content: "\e965"; }

.icon-add-button2:before {
  content: "\e966"; }

.icon-stack:before {
  content: "\e967"; }

.icon-close-btn:before {
  content: "\e968"; }

.icon-close-btn1:before {
  content: "\e969"; }

.icon-trash:before {
  content: "\e96a"; }

.icon-close-solid:before {
  content: "\e96b"; }

.icon-unlock:before {
  content: "\e96c"; }

.icon-lock:before {
  content: "\e96d"; }

.icon-pin:before {
  content: "\e96e"; }

.icon-picture-circle-button:before {
  content: "\e96f"; }

.icon-text-circle-button:before {
  content: "\e970"; }

.icon-add-circle-button:before {
  content: "\e971"; }

.icon-text:before {
  content: "\e972"; }

.icon-edit:before {
  content: "\e973"; }

.icon-refresh:before {
  content: "\e974"; }

.icon-eye:before {
  content: "\e975"; }

.icon-flag:before {
  content: "\e976"; }

.icon-picture-1:before {
  content: "\e977"; }

.icon-loading-arrows:before {
  content: "\e978"; }

.icon-sync-1:before {
  content: "\e979"; }

.icon-sync:before {
  content: "\e97a"; }

.icon-add-link:before {
  content: "\e97b"; }

.icon-circle-solid:before {
  content: "\e97c"; }

.icon-pdf:before {
  content: "\e97d"; }

.icon-spotify:before {
  content: "\e97e"; }

.icon-user-3:before {
  content: "\e97f"; }

.icon-user-3:before {
  content: "\e980"; }

.icon-user-3:before {
  content: "\e981"; }

.icon-recycle-black:before {
  content: "\e982"; }

.icon-dice:before {
  content: "\e983"; }

.icon-eventbrite:before {
  content: "\e987"; }

.icon-userlogin-1:before {
  content: "\e9aa"; }

.icon-userlogin-2:before {
  content: "\e9ac"; }

.icon-labels-fit:before {
  content: "\e9ae"; }

.icon-pro:before {
  content: "\e9a6"; }

.icon-radio-fit:before {
  content: "\e9a9"; }

.icon-labels-center:before {
  content: "\e9a7"; }

.icon-pro-center:before {
  content: "\e9a5"; }

.icon-volum_off:before {
  content: "\e9b1"; }

.icon-volum_on:before {
  content: "\e9a0"; }

.icon-userlogin-3:before {
  content: "\e9ad"; }

.icon-via-verde-logo:before {
  content: "\e990"; }

.icon-bol-logo:before {
  content: "\e991"; }

.icon-onu:before {
  content: "\e99e"; }

.icon-recycle-cups:before {
  content: "\e99f"; }

.icon-userlogin:before {
  content: "\e9ab"; }

.icon-duplicate:before {
  content: "\e95e"; }

.icon-deny:before {
  content: "\e909"; }

.icon-verified:before {
  content: "\e908"; }
.icon-visibility-1:before {
  content: "\e900"; }

.icon-visibility-2:before {
  content: "\e903"; }

.icon-visibility-3:before {
  content: "\e901"; }

.icon-visibility-4:before {
  content: "\e905"; }

.icon-rps-cupra:before {
  content: "\e900"; }

.icon-notification:before {
  content: "\e901"; }

.icon-amazon-music-logo:before {
  content: "\e902"; }

.icon-AmazonMusicLogo:before {
  content: "\e903"; }
.icon-RDL-notification:before {
  content: "\e904"; }

.icon-new-notification:before {
  content: "\e905"; }

// #topHeader
//   .tickets
//     background: #C8C8C8
//     border-radius: 31.5px
//   #menuContainer
//     #headerSubmenu
//       padding-left: 3rem
//       position: relative
//       &>div
//         button:last-child
//           border-right: none !important
//     #headerMenu
//       button
//         border: none
//         font-family: GT-America-Mono-Bold
//       a
//         color: black
//       .headerMenuButtonTickets
//         background: #C8C8C8
.triangle {
  width: 0;
  height: 0;
  content: '';
  border-bottom: 36px solid white;
  border-right: 40px solid black;
  position: absolute; }

.triangle-black {
  width: 0;
  height: 0;
  content: '';
  border-bottom: 36px solid white;
  border-right: 40px solid black;
  position: absolute; }

.triangle-white {
  width: 0;
  height: 0;
  content: '';
  border-bottom: 36px solid black;
  border-right: 40px solid white;
  position: absolute; }

.triangle-transparent {
  width: 0;
  height: 0;
  content: '';
  border-bottom: 36px solid #EFEAE7;
  border-right: 40px solid black;
  position: absolute;
  left: 0;
  @include sm-xl {
    border-bottom: 64px solid #EFEAE7;
    border-right: 63px solid black; } }

.triangle-transparent-xl {
  width: 0;
  height: 0;
  content: '';
  border-bottom: 79px solid #EFEAE7;
  border-right: 78px solid black;
  position: absolute;
  left: 0;
  @include sm-md {
    border-bottom: 107px solid #EFEAE7;
    border-right: 100px solid black; }
  @include md-lg {
    border-bottom: 104px solid #EFEAE7;
    border-right: 100px solid black; }
  @include lg-xl {
    border-bottom: 105px solid #EFEAE7;
    border-right: 100px solid black; }
  @include min-xl {
    border-bottom: 103px solid #EFEAE7;
    border-right: 100px solid black;
    margin-top: -1.25rem; } }

.fixedHeader {
  position: fixed;
  top: -152px;
  transition: top 0.3s linear; }

.fixedHeaderActivationBar {
  position: fixed;
  top: -144px;
  transition: top 0.3s linear; }

.fixedHeaderShow {
  position: fixed;
  top: 0px;
  transition: top 0.3s linear; }

.trans {
  transition: all .3s linear; }
.trans-fast {
  transition: transform .1s linear; }
.trans-opacity {
  transition: opacity .3s linear, margin .3s linear; }

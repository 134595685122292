$sm-width: 640px;
$md-width: 992px;
$lg-width: 1199px;
$xl-width: 1680px;

@mixin sm-lg {
  @media (min-width: #{$sm-width}) and (max-width: #{$lg-width}) {
    @content; } }
@mixin sm-md {
  @media (min-width: #{$sm-width}) and (max-width: #{$md-width}) {
    @content; } }
@mixin md-lg {
  @media (min-width: #{$md-width}) and (max-width: #{$lg-width}) {
    @content; } }
@mixin sm-xl {
  @media (min-width: #{$sm-width}) and (max-width: #{$xl-width}) {
    @content; } }
@mixin sm {
  @media (max-width: #{$sm-width}) {
    @content; } }
@mixin md {
  @media (min-width: #{$md-width}) and (max-width: #{$md-width}) {
    @content; } }
@mixin not-sm {
  @media (min-width: #{$sm-width}) {
    @content; } }
@mixin min-xl {
  @media (min-width: #{$xl-width}) {
    @content; } }
@mixin lg-xl {
  @media (min-width: #{$lg-width}) and (max-width: #{$xl-width}) {
    @content; } }

@mixin gradient($direction, $list) {
  background: -webkit-linear-gradient($direction, $list);
  background: -moz-linear-gradient($direction, $list);
  background: -o-linear-gradient($direction, $list);
  background: linear-gradient($direction, $list); }


a {
  .MuiButtonBase-root-18 {
    background: white; } }

a {
  .MuiTypography-body1-36 {
      color: #000!important; } }

a.active {
  .MuiButtonBase-root-18 {
    background: black!important; } }

a.active {
  .MuiButtonBase-root {
    background: black!important; } }

a.active {
  .MuiTypography-body1 {
      color: #fff!important; } }

a.active {
  .MuiTypography-body1-36 {
      color: #fff!important; } }

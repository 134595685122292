// Tablets and small desktops
$screen-lg-min: 992px;
$screen-xl-min: 1280px;
$screen-md-min: 640px;
// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content; } }
@mixin smMax {
  @media (max-width: #{$screen-md-min}) {
    @content; } }
@mixin mdMax {
  @media (max-width: #{$screen-lg-min}) {
    @content; } }
.skeleton-bg {
    background-color: #eee;
    background-image: linear-gradient( 90deg, #eee, #f5f5f5, #eee); }
.launcher {
  .launcherItem {
    img {
      display: block; }
    .single, .concert, .launcherBannerHalf, .launcherBanner, .launcherBannerTours, .launcherBannerToursHalf {
      .launcherItemImg {
        overflow: hidden;
        border-top: none;
        &:before {
          content: '';
          background-color: rgba(0,0,0,0.10);
          width: 100%;
          height: 100%;
          position: absolute;
          bottom: 0;
          left: 0; }
        &:after {
          content: '';
          background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 30%, #000000 100%);
          width: 100%;
          height: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          border-bottom: 2px solid #efeae7;
          &:hover {
            display: none; } } } }
    .single-news {
      .launcherItemImg {
        border-bottom: 4px solid #000;
        border-top: 1px solid transparent;
        &:after {
          border-bottom: 0px solid #efeae7; } } }
    .launcherItemData, .concert {
      overflow: hidden; }
    &.triple {
      > div {
        border-top: 1px solid black; }
      &:nth-child(2) {
        padding-top: .5rem; }
      &:nth-last-child(1) {
        > div:last-child {
          border-bottom: 1px solid black; } }
      @media screen and (min-width: $screen-md-min) {
        &:nth-child(2) {
          padding-bottom: 1rem;
          margin-top: 2px;
          > div:last-child {
            border-bottom: 1px solid black; } }
        &:nth-last-child(2) {
          > div:last-child {
            border-bottom: 1px solid black; } } }
      @media screen and (min-width: $screen-xl-min) {
        &:nth-child(2) {
          margin-top: 2px; }
        &:nth-child(3) {
          margin-top: 2px;
          padding-top: .5rem;
          padding-bottom: .5rem;
          > div:last-child {
            border-bottom: 1px solid black; } }
        &:nth-last-child(3) {
          > div:last-child {
            border-bottom: 1px solid black; } } } } }
  &:nth-child(2) {
    margin-top: .5rem; } }

.launcherItemData {
  input {
    &::placeholder {
      color:#fff {} } } }
.launcherGradientContainer {
  &:after {
    content: '';
    background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 50%, #000000 120%);
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    &:hover {
      display: none; } } }

.launcherOverlayContainer {
  &:after {
    content: '';
    background: rgba(0,0,0,.2);
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    &:hover {
      display: none; } } }

@keyframes imageFadeIn {
  0% {
    filter: grayscale(0%); }
  100% {
    filter: grayscale(100%); } }

@keyframes imageFadeOut {
  0% {
    filter: grayscale(100%); }
  100% {
    filter: grayscale(0%); } }


.noBackgroundImgGrayContainer {
  &:after {
    content: '';
    background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 30%, #000000 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0; } }

img.noBackgroundImgGray {
  @include lg {
    filter: none;
    animation: none; } }

.textOnHover {
  transition: opacity .2s, visibility .2s;
  top: 0; }

.textOnHoverWrapper:hover {
  .textOnHover {
    visibility: visible;
    opacity: 1; } }

.tweet {
  max-height: 250px;
  object-fit: scale-down; }

.colorImg {
  filter: none;
  animation: none; }

button {
  &[tag="nos"] {
    background: #7DD6AD; }
  &[tag="concerts"] {
    background: #84A6E6; }
  &[tag="barcelona"] {
    background: #FE808B; }
  &[tag="club"] {
    background: #FFAF52; } }

@keyframes fadeInImg {
 from {
  opacity: 0; }
 to {
  opacity: 1; } }
.img-loading {
   opacity: 0;
   width: 100%;
   height: auto; }
.img-loaded {
   animation: fadeInImg .7s;
   opacity: 1; }

.sliderComponent, .filter {
  border-top: none;
  .sliderBackground {
    -webkit-transition: transform 0.45s {
      transition: transform 0.45s; } }
  &:before {
    content: '';
    background-color: rgba(0,0,0,.1);
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0; }
  &:after {
    content: '';
    background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 50%, #000000 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    &:hover {
      display: none; } }
  &.h-screen40 {
    &:after {
      background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, #000000 130%); } } }

.sliderComponent {
  @include mdMax;
  &:after {
    content: '';
    background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 30%, #000000 130%);
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    &:hover {
      display: none; } }
  @include smMax {
    &:after {
      content: '';
      background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, #000000 130%);
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      &:hover {
        display: none; } } } }

.object-cover {
  object-fit: cover; }

.artistListItem {
  display: flex;
  cursor: pointer;
  align-items: center;
  .artistCard {
    display: none;
    right: -274px;
    @include sm {
      left: 50%;
      margin-left: -128px;
      bottom: -458px; } }
  @include not-sm {
    &:hover {
      .artistCard {
        display: block; } } } }

.artistCardActive {
  padding-bottom: 446px;
  .artistCard {
    display: block; } }

$screen-i6-min: 360px;
$screen-plus-min: 411px;
$screen-plus-min-responsive: 420px;
$screen-plus-min-responsive-max: 520px;
$screen-before-md: 565px;
$screen-md-min: 640px;
$screen-lg-min: 992px;
$bgps: #efeae7;
$grey: #f0f0f0;
$white: #ffffff;
$list: $bgps, $grey, $white;

.MuiTypography-root.text-white, .MuiTypography-body2.text-white {
  color: #fff!important;
}
.MuiTypography-root.text-xs, .MuiTypography-body2.text-xs {
  font-size: .75rem!important;
}

.MuiTypography-root.font-americaMonoRegular, .MuiTypography-body2.font-americaMonoRegular{
  font-family: 'GT-America-Mono-Regular'!important;
}

.bg-gradient-ps-light {
  @include gradient(top,$list)
}

.translate-x-0 {
  transform: translateX(0);
}
.-translate-x-w-chat {
  transform: translateX(-100vw);
}

.scrollbar__streampage::-webkit-scrollbar-track {
  background-color: #000000;
}

.scrollbar__streampage::-webkit-scrollbar {
  width:0;
  background-color: #000000;
}

.scrollbar__streampage::-webkit-scrollbar-thumb {
  background-color: #000000;
}

.single-stream-page > *:not(.full-width) {
  padding-left: 1rem;
  padding-right: 1rem;
}
.react-toast-notifications__container {
  overflow-y: hidden!important;
}
.react-toast-notifications__container{
  max-height: 40px!important;
  height: 40px!important;
  padding: 0!important;
  position: absolute!important;
}

.react-toast-notifications__toast{
  border-radius: 0!important;
  background-color: #887AFF!important;
  color: #fff!important;
  padding: 0!important;
}
.react-toast-notifications__toast__content{
  font-size: .875rem!important;
  font-family: "GT-America-Standard-Regular";
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 8px!important;
}

.react-toast-notifications__toast__icon-wrapper{
  display: none!important;
}

.single-stream-page {
  .font-tiemposRegular {
    font-family: "GT-America-Standard-Regular";
    font-size: 1.125rem;
    line-height: 1.35;
  }
  h1, h2{
    font-size: 1.5rem;
  }
  h2:not(.font-americaBold){
    font-family: "GT-America-Standard-Regular";
  }
  .ps-zone {
    h2{
      font-family: "GT-America-Standard-Bold";
    }
  }
  h1 + h2{
    margin-top: -.5rem;
    margin-bottom: 1rem;
  }
}

.rules-card a, .message-item a {
  color: #fff;
  &:hover {
    text-decoration: underline;
  }
}

.single-stream-page{
  .stream-window-wrapper {
    position: fixed;
    top: 105px;
    padding-bottom: 1rem;
    z-index: 9;
  }
  &.fullscreen {
    .stream-window-wrapper {
      top: 0;
    }
  }
  &.hide-chat {
    padding-right: 0;
    .chat-sidebar {
      bottom: -100%;
    }
  }
}

// @media screen and (min-width: $screen-i6-min) { 
//   .single-stream-page{
//     .chat-sidebar {
//       bottom: 0;
//       height: calc(100% - 358px);
//     }
//     .stream-content-wrapper {
//       padding-top: 258px;
//     }
//     .stream-window-wrapper {
//       position: fixed;
//       top: 105px;
//       padding-bottom: 1rem;
//     }
//     &.fullscreen {
//       .chat-sidebar {
//         height: calc(100% - 235px);
//       }
//       .stream-window-wrapper {
//         top: 0;
//       }
//       .stream-content-wrapper {
//         padding-top: 173px;
//       }
//     }
//     &.hide-chat {
//       padding-right: 0;
//       .chat-sidebar {
//         display: none;
//       }
//     }
//   }

// }

// @media screen and (min-width: $screen-plus-min) { 
//   .single-stream-page{
//     .chat-sidebar {
//       bottom: 0;
//     }
//     .stream-content-wrapper {
//       padding-top: 274px;
//     }
//     &.fullscreen {
//       .stream-content-wrapper {
//         padding-top: 260px;
//       }
//     }
//     &.hide-chat {
//       padding-right: 0;
//       .chat-sidebar {
//         display: none;
//       }
//     }
//   }

// }
// @media screen and (min-width: $screen-plus-min-responsive) { 
//   .single-stream-page{
//     .chat-sidebar {
//       bottom: 0;
//     }
//     .stream-content-wrapper {
//       padding-top: 310px;
//     }

//     &.hide-chat {
//       padding-right: 0;
//       .chat-sidebar {
//         display: none;
//       }
//     }
//   }

// }
// @media screen and (min-width: $screen-plus-min-responsive-max) { 
//   .single-stream-page{
//     .chat-sidebar {
//       bottom: 0;
//       height: calc(100% - 460px);
//     }
//     .stream-content-wrapper {
//       padding-top: 360px;
//     }

//     &.fullscreen {
//       .chat-sidebar {
//         height: calc(100% - 325px);
//       }
//       .stream-content-wrapper {
//         padding-top: 225px;
//       }
//     }
//     &.hide-chat {
//       padding-right: 0;
//       .chat-sidebar {
//         display: none;
//       }
//     }
//   }

// }
// @media screen and (min-width: $screen-before-md) { 
//   .single-stream-page{
//     .chat-sidebar {
//       bottom: 0;
//       height: calc(100% - 500px);
//     }
//     .stream-content-wrapper {
//       padding-top: 400px;
//     }

//     &.fullscreen {
//       .chat-sidebar {
//         height: calc(100% - 395px);
//       }
//       .stream-content-wrapper {
//         padding-top: 295px;
//       }
//     }
//     &.hide-chat {
//       padding-right: 0;
//       .chat-sidebar {
//         display: none;
//       }
//     }
//   }

// }

@media screen and (min-width: $screen-md-min) {
  .-translate-x-w-chat {
    transform: translateX(-338px);
  }
  .react-toast-notifications__container{
    position: absolute!important;
    bottom: 104px!important;
    width: 338px;
}
.react-toast-notifications__toast{
  width: 100%!important;
}
  .single-stream-page{
    .chat-toggler{
      top: 104px;
      right: 288px;
      border-bottom-left-radius: 1.2rem;
    }
    .chat-sidebar {
      height: 100%;
      padding-top: 105px;
    }
    .stream-content-wrapper {
      padding-top: 0;
    }
    .stream-window-wrapper {
      position: static;
      top: 0;
      padding-bottom: 0;
    }
    &.fullscreen {
      .chat-sidebar {
        padding-top:0!important;
      }
      .chat-toggler{
        top: 0;
      }
    }
    &.hide-chat {
      padding-right: 0;
      .chat-sidebar {
        display: none;
        bottom:0;
        right: -100%
      }
      .chat-toggler{
        right: -6px;
      }
    }
  }
}

@media screen and (min-width: $screen-lg-min) {
  .single-stream-page{
    .font-tiemposRegular {
      padding-right: 100px;
    }
  }
} 


